<template>
  <!-- <div class="main-layout"> -->
  <div id="page-top" class="pt-70">
    <div class="container-fluid">
      <child />
    </div>
    <div class="navbar-fixed">
      <navbar />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from "~/components/NavbarAdmin";

export default {
  name: "AdminLayout",

  middleware: 'auth',
  components: {
    Navbar,
  },
  computed: mapGetters({
    user: "auth/user",
  }),
  mounted(){
    if(!this.user || this.user.role != 'admin'){
      this.$router.push({ name: 'home' })
    }
  },
};
</script>


<style lang="scss">
.pt-70 {
  padding-top: 70px;
}
.navbar-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #1f63af;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 0%,
    rgba(5, 86, 171, 1) 0%,
    rgba(2, 159, 220, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
}
</style>
