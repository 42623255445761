<template>
  <div id="page-top">
    <div class="navbar-absolute">
      <navbar :noeng="true" />
    </div>
    <!-- class="basic-layout d-flex align-items-center justify-content-center m-0 bg-white"> -->
    <child />
  </div>
</template>

<script>
import Navbar from "~/components/MainNavbar";

export default {
  name: "HomeLayout",

  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
.navbar-absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.basic-layout {
  color: #636b6f;
  height: 100vh;
  font-weight: 100;
  position: relative;

  .links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-decoration: none;
    text-transform: uppercase;
  }
}
</style>
