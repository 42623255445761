function page (path) {
  return () => import(/* webpackChunkName: '' */ `~/pages/${path}`).then(m => m.default || m)
}

export default [
  // { path: '/', name: 'index', component: page('index.vue') },
  { path: '/', name: 'index', component: page('vendor/home_cn.vue') },
  { path: '/index2', name: 'welcome', component: page('welcome.vue') },

  { path: '/login', name: 'login', component: page('auth/login.vue') },
  { path: '/register', name: 'register', component: page('auth/register.vue') },
  { path: '/password/reset', name: 'password.request', component: page('auth/password/email.vue') },
  { path: '/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue') },
  { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
  { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },

  { path: '/home', name: 'home', component: page('home.vue') },
  {
    path: '/settings',
    component: page('settings/index.vue'),
    children: [
      { path: '', redirect: { name: 'settings.profile' } },
      { path: 'profile', name: 'settings.profile', component: page('settings/profile.vue') },
      { path: 'password', name: 'settings.password', component: page('settings/password.vue') }
    ]
  },
  {
    path: '/admin',
    component: page('admin/index.vue'),
    children: [
      { path: '', redirect: { name: 'admin.cms' } },
      { path: 'cms', name: 'admin.cms', component: page('admin/content.vue') },
      { path: 'page', name: 'admin.page', component: page('admin/page.vue') },
      { path: 'cms/preview', name: 'admin.cms.preview', component: page('vendor/home.vue') },
      { path: 'user', name: 'admin.user', component: page('admin/user.vue') },
      { path: 'file', name: 'admin.file', component: page('admin/file.vue') },
    ]
  },
  { path: '/preview', name: 'cms.preview', component: page('vendor/home.vue') },

  { path: '*', component: page('errors/404.vue') }
]
