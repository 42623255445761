<template>
  <!-- <div class="main-layout"> -->
  <div id="page-top">
    <navbar />
    <child />
    <!-- <div class="container mt-4">
    </div> -->
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'

export default {
  name: 'MainLayout',

  components: {
    Navbar
  }
}
</script>
